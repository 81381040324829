import { Link } from '@remix-run/react'
import React from 'react'
import { $path } from 'remix-routes'
import { Badge, Card, CardContent } from '~/components/ui'
import dayjs from '~/libs/dayjs'
import type { NewsItem } from '../interfaces/news'

interface NewsIndexProps extends React.HTMLAttributes<HTMLDivElement> {
  limit?: number
  items?: NewsItem[]
}
export const NewsIndex: React.FC<NewsIndexProps> = ({ items, limit = 10, ...rest }) => {
  const newsItems = items?.slice(0, limit)
  if (!newsItems) {
    return (
      <Card {...rest}>
        <CardContent className="p-4 text-muted">更新情報はありません</CardContent>
      </Card>
    )
  }

  return (
    <Card {...rest}>
      <CardContent className="p-4">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-[auto_1fr]">
          {newsItems.map((item) => (
            <React.Fragment key={item.id}>
              <div className="flex flex-row items-start gap-0 md:gap-2">
                <div className="text-sm">{dayjs(item.publishedAt).tz().format('YYYY年M月D日')}</div>
                <Badge variant="outline">{item.category}</Badge>
              </div>
              <div className="text-left">
                <Link className="hover:underline" to={$path('/news/:id', { id: item.id })}>
                  {item.title}
                </Link>
              </div>
            </React.Fragment>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}
